<template>
    <div class="body">
        <div class="mct-login">
            <img src="../../assets/images/Mercato_W224_marron.png" alt="Mercato" class="logo"/>
            <div class="form">
                <div class="alert alert-danger" role="alert" v-if="usu_erro !== ''" style="text-align: center;">
                    <strong >{{ usu_erro }}</strong>
                </div>
                <form autocomplete="off" @submit.prevent.stop="logar">
                    <div class="input-group input-group-lg mb-4 d-flex flex-row align-items-center">
                        <span class="input-group-addon" style="text-align: center;"><i class="fa fa-user fa-lg" aria-hidden="true"></i></span>
                        <input type="text" class="form-control border-0 text-dark" id="usu_usuario" placeholder="usuário" v-model="usu_usuario" required>
                    </div>
                    <div class="input-group input-group-lg mb-2 d-flex flex-row align-items-center">
                        <span class="input-group-addon" style="text-align: center;"><i class="fa fa-lock fa-lg" aria-hidden="true"></i></span>
                        <input type="password" class="form-control text-dark" id="usu_senha" placeholder="senha" v-model="usu_senha" required>
                    </div>
                    <div class="text-right mb-2"><a href="#">Esqueceu sua senha?</a></div>
                    <button type="submit" class="btn btn-dark btn-block"><i class="fa fa-sign-in fa-lg text-white" aria-hidden="true" style="float: left; margin-top: 4px;"></i>Logar</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'
import LayoutLogin from '@/components/layouts/LayoutLogin.vue';

export default {
    data() {
            return {
                //usu_usuario: 'admin',
                //usu_senha: '602123',
                usu_usuario: '',
                usu_senha: '',
                usu_erro: '',
            }
        },
    methods: {
        getIp (){
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    axios.defaults.params = {'mct_ip': ip}
                })
                .catch(response => {
                });
        },
        async logar(event) {
            event.preventDefault()            
            await axios.post('auth/login',
            {
                usu_usuario: this.usu_usuario.toLowerCase(),
                usu_senha: this.usu_senha
            }
            ).then(response => {
                if (response.data.response === 'error'){
                    this.usu_erro = response.data.message
                } else{
                    this.usu_erro = ''
                    this.$store.dispatch('Login/USU_LOGADO', {user: response.data.result, router: this.$router});
                    this.$store.dispatch('Login/USU_LOGAR_SUCCESS', true);
                    this.$store.dispatch('Login/USU_ULTEXERCICIO');
                    this.$store.dispatch('App/APP_ATUALIZA');
                }
            }, response => {
                this.$store.dispatch('Login/USU_LOGAR_SUCCESS', false);
            })

        }
    },
    created () {
        this.$store.dispatch('Login/USU_IP');
    },
    updated () {

    },
    mounted () {
        this.$store.dispatch("Login/LAYOUT",LayoutLogin);
    },  
}

</script>

<style scoped>
    .body {
        /*background-color: #E8E8E8!important;*/
        background: #663a2a;
        background: radial-gradient(circle, rgba(102,58,42,1) 0%, rgba(0,0,0,1) 100%);
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo{
        margin: 0 auto;
        max-width: 250px;
        top: -150px;
        position: absolute;
        left: 25%;
        text-align: center;
    }

    .mct-login{
        width: 400px;
        background-color: #FFFFFF;
        /*border: 1px solid #f1f1f1;*/
        border-radius: 4px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
    }

    .form{
        padding: 40px;
    }

    .form a {
        color: #1E2225;
    }

    .form a:hover {
        color: #b6b6b6;
        text-decoration: none;
    }

    input {
        padding: 0.375rem 0.3rem;
        font-size: 15px!important;
        background-color: #F2F2F2;        
    }

    .input-group-addon{
        color: #b6b6b6;
        width: 45px!important;
    }

    .input-group{
        font-size: 1.05em!important;
        border-radius: 4px!important;
        border: 1px solid #b6b6b6;
        background-color: #F2F2F2;
    }

    .mct-login .input-group input{
        /*color: #363636 !important;*/
    }

    .form-control {
        border: 0!important;
    }

    .btn {
        border-radius: 4px!important;
        height: 42px;
    }

    label,
    input[placeholder], [placeholder], *[placeholder] {
        color: #b6b6b6;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    #b6b6b6;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #b6b6b6;
        opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #b6b6b6;
        opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color:    #b6b6b6;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color:    #b6b6b6;
    }

</style>
